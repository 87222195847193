body {
  margin: 0;
  font-size: 14px;
  background: #f5f5f5;
  color: #222222;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dvf-popup .mapboxgl-popup-content {
  padding: 0;
  width: 225px;
  height: 115px;
  border-radius: 8px;
}

[data-tour-elem="controls"] {
  justify-content: center;
}

.product-tour-overlay {
  padding: 0px;
  color: rgba(0, 0, 0, 0.3);
}
